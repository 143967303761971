import React, { useState } from "react"
import { navigate } from "gatsby"

import { Wrapper, FormElement, Label, Input } from "components/login/Form"
import useAuth from "hooks/useAuth"
import * as C from "shared/components"

const Login = () => {
  const { login } = useAuth()
  const [identifier, setIdentifier] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      await login({ identifier, password })

      navigate("/")
    } catch (e) {
      if (e.response.data.message[0].messages[0].message) {
        setError(e.response.data.message[0].messages[0].message)
      }
    }
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <FormElement>
          <Label htmlFor="username">Email</Label>
          <Input
            onChange={e => {
              setIdentifier(e.target.value)
            }}
            value={identifier}
            id="username"
            type="text"
          />
        </FormElement>
        <FormElement>
          <Label htmlFor="password">Password</Label>
          <Input
            onChange={e => {
              setPassword(e.target.value)
            }}
            value={password}
            id="password"
            type="password"
          />
        </FormElement>
        <C.Button fontFamily="ttCommons" color="darkGreyBlue" type="submit">
          Sign In
        </C.Button>

        {error !== "" && <p>{error}</p>}
      </form>
    </Wrapper>
  )
}

export default Login
