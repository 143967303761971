import styled from "styled-components"

import { theme } from "shared/styles/theme"

export const Wrapper = styled.div`
  max-width: 400px;
  margin: 50px auto 0 auto;
  font-family: ${theme.font.family.robotoRegular};
`

export const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const Label = styled.label`
  margin-bottom: 5px;
`

export const Input = styled.input`
  height: 35px;
`
